
import { defineComponent, ref } from 'vue';
import  UIModal from '@/components/UI/UIModal.vue';
import UIButton from '../UI/UIButton.vue';
import { showToast } from '@/hooks/useToast';

export default defineComponent({
  name: 'ExcelRequest',
  components: {
    'ui-modal': UIModal,
    'ui-button': UIButton
  },
  props: {
    cb: {
      type: Function,
      default: () => console.log('REPORT REQUESTED !')
    }
  },
  setup(props) {
    const modalState = ref(false);
    const isCreating = ref(false);
    const report = async () => {
      if (isCreating.value) {
        showToast('INFO', 'En proceso...');
        return;
      }
      modalState.value = false;
      isCreating.value = true;
      showToast('INFO', 'Creando Reporte');
      await props.cb();
      isCreating.value = false;
    };
    return {
      modalState,
      report
    };
  }
});
