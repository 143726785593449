
import { computed, onMounted, ref, defineComponent, ComponentPublicInstance, reactive } from 'vue';
import { makeRequest, METHODS } from '@/utils/apiUtils';
import { useRoute } from 'vue-router';
import UITable from '@/components/UI/UITable.vue';
import UIModal from '@/components/UI/UIModal.vue';
import UITextDropdown from '@/components/UI/UITextSelect.vue';
import UITextInput from '@/components/UI/UITextInput.vue';
import UIButton from '@/components/UI/UIButton.vue';
import { userState } from '@/store/user.store';
import { validateForm } from '@/utils';

export default defineComponent({
  name: 'Canchas',
  components: {
    'ui-table': UITable,
    'ui-modal': UIModal,
    'ui-text-input': UITextInput,
    'ui-text-select': UITextDropdown,
    'ui-button': UIButton
  },
  setup() {
    const userStoreState = userState.value;
    const route = useRoute();
    const routeName = computed(() => (route.meta?.routeName ? route.meta?.routeName : 'no-name'));
    const modalState = ref(false);
    const modalRemovalState = ref(false);
    const modalMode = ref<'CREATE' | 'EDIT'>('CREATE');
    const isLoading = ref(false);

    const codigoRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const nombreRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const tiposRef = ref<ComponentPublicInstance<typeof UITextDropdown> | null>(null);
    const estadosRef = ref<ComponentPublicInstance<typeof UITextDropdown> | null>(null);
    const siscopRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);

    const formData = reactive<{ [k: string]: string | undefined }>({
      codigo: '',
      descripcion: '',
      tipo: '',
      estado: '',
      codigoSISCOP: ''
    });

    const estados = [
      { id: 'ACTIVO', label: 'ACTIVO' },
      { id: 'INACTIVO', label: 'INACTIVO' }
    ];
    const canchaTipos = [
      { id: 'CANCHA', label: 'CANCHA' },
      { id: 'PLANTA', label: 'PLANTA' }
    ];

    const tableHeaders = [
      { label: 'Código', key: 'codigo', format: '' },
      { label: 'Nombre', key: 'descripcion', format: '' },
      { label: 'Estado', key: 'activo', format: '' },
      { label: 'Tipo', key: 'tipo', format: '' },
      { label: 'codigoSISCOP', key: 'codigoSISCOP', format: '' }
    ];
    const tableItems = ref<any[]>([]);

    const selectedToRemoval = ref<any>(null);
    function onRemoveCanchaOpenModal(i: number) {
      selectedToRemoval.value = tableItems.value[i];
      modalRemovalState.value = true;
    }

    const selectedToEdit = ref<any>(null);
    const selectedTipoDefault = ref('');
    const selectedEstadDefault = ref('');
    function onEditCanchaOpenModal(i: number) {
      modalMode.value = 'EDIT';
      const selected = tableItems.value[i];
      selectedToEdit.value = tableItems.value[i];
      formData.codigo = selected.data.codigo;
      formData.descripcion = selected.data.descripcion;
      formData.tipo = selected.data.tipo ? selected.data.tipo : 'CANCHA';
      formData.estado = selected.data.activo ? 'ACTIVO' : 'INACTIVO';
      formData.codigoSISCOP = selected.data.codigoSISCOP;
      selectedTipoDefault.value = formData.tipo ? formData.tipo : 'CANCHA';
      selectedEstadDefault.value = formData.estado;
      modalState.value = true;
    }
    async function onEditCancha() {
      isLoading.value = true;
      const response = await makeRequest(METHODS.PATCH, `/cancha`, true, { ...formData, id: selectedToEdit.value.uid });
      if (response.ok) {
        modalState.value = false;
        const i = tableItems.value.findIndex(item => item.uid === selectedToEdit.value.uid);
        tableItems.value[i].data.codigo = formData.codigo;
        tableItems.value[i].data.descripcion = formData.descripcion;
        tableItems.value[i].data.tipo = formData.tipo ? formData.tipo : 'CANCHA';
        tableItems.value[i].data.activo = formData.estado ?'ACTIVO':'INACTIVO';
        tableItems.value[i].data.codigoSISCOP = formData.codigoSISCOP;
      }
      isLoading.value = false;
    }

    const actions = [ 
      { label: 'EDITAR', class: 'bg-yellow-600 text-white', cb: (i: number) => onEditCanchaOpenModal(i) },
      { label: 'ELIMINAR', class: 'bg-red-600 text-white', cb: (i: number) => onRemoveCanchaOpenModal(i) }
    ];

    async function onDeleteCancha() {
      isLoading.value = true;
      const response = await makeRequest<any>(METHODS.DELETE, `/cancha`, true, { id: selectedToRemoval.value.uid});
      if (response.ok) {
        const i = tableItems.value.findIndex(item => item.uid === selectedToRemoval.value.uid);
        tableItems.value.splice(i, 1);
        selectedToRemoval.value = null;
        modalRemovalState.value = false;
      }
      isLoading.value = false;
    }

    function openModalToCreate() {
      modalMode.value = 'CREATE';
      modalState.value = true;
      selectedTipoDefault.value = '';
      selectedEstadDefault.value = '';
      formData.codigo = '';
      formData.descripcion = '';
      formData.tipo = '';
      formData.estado = '';
      formData.codigoSISCOP = '';      
    }
    async function onCreateCancha() {
      console.log('create');
      const isValid = validateForm([tiposRef, estadosRef, nombreRef, codigoRef, siscopRef]);
      if (isValid) {
        console.log('isValid');
        isLoading.value = true;
        const response = await makeRequest<any>(METHODS.POST, 'cancha', true, { ...formData });
        console.log(response);
        if (response.ok && response.payload) {
          const cancha = response.payload;
          tableItems.value.push({
            uid: cancha._id,
            data: {
              codigo: cancha.codigo,
              descripcion: cancha.descripcion,
              tipo: cancha.tipo ? cancha.tipo : 'CANCHA',
              activo: cancha.activo ? 'ACTIVO' : 'INACTIVO',
              codigoSISCOP: cancha.codigoSISCOP
            }
          });
          modalState.value = false;
        }
        isLoading.value = false;
      }
    }

    onMounted(async () => {
      const request = await makeRequest<any>(METHODS.GET, '/canchas', false);
      tableItems.value = request.payload.map((el: any) => {
        return {
          uid: el._id,
          data: {
            codigo: el.codigo,
            descripcion: el.descripcion,
            activo: el.activo ? 'ACTIVO' : 'INACTIVO',
            tipo: el.tipo ? el.tipo : 'CANCHA',
            codigoSISCOP: el.codigoSISCOP
          }
        };
      });
    });

    return {
      routeName,
      tableHeaders,
      tableItems,
      userStoreState,
      modalState,
      modalMode,
      openModalToCreate,
      estados,
      canchaTipos,
      isLoading,
      onCreateCancha,
      tiposRef,
      estadosRef,
      siscopRef,
      codigoRef,
      nombreRef,
      formData,
      actions,
      modalRemovalState,
      selectedToRemoval,
      onDeleteCancha,
      selectedTipoDefault,
      selectedEstadDefault,
      onEditCancha
    };
  }
});
