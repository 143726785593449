
import { ComponentPublicInstance, computed, defineComponent, onMounted, reactive, ref } from 'vue';
import UIFilterSelect from '@/components/UI/UIFilterSelect.vue';
import UIFilterInput from '@/components/UI/UIFilterInput.vue';
import {canchasState, estadoRumasState} from '@/store/mantenedores.store';
import { cleanSource } from '@/utils';
import { makeRequest, METHODS } from '@/utils/apiUtils';
import moment from 'moment-timezone';

export default defineComponent({
  name: 'TableFilter',
  components: {
    'ui-filter-select': UIFilterSelect,
    'ui-filter-input': UIFilterInput
  },
  emits: ['filter', 'unfilter'],
  setup(_props, { emit }) {
    const origenRef = ref<ComponentPublicInstance<typeof UIFilterSelect> | null>(null);
    const rumaRef = ref<ComponentPublicInstance<typeof UIFilterSelect> | null>(null);
    const movimientoRef = ref<ComponentPublicInstance<typeof UIFilterSelect> | null>(null);
    const estadoRef = ref<ComponentPublicInstance<typeof UIFilterSelect> | null>(null);
    const desdeRef = ref<ComponentPublicInstance<typeof UIFilterInput> | null>(null);
    const hastaRef = ref<ComponentPublicInstance<typeof UIFilterInput> | null>(null);
    const tipoRef = ref<ComponentPublicInstance<typeof UIFilterInput> | null>(null);
    const estadoRumaRef = ref<ComponentPublicInstance<typeof UIFilterSelect> | null>(null);
    const movimientos = [
      { label: 'COMPRA', id: 1 },
      { label: 'VENTA', id: 2 },
      { label: 'DESPACHO', id: 3 },
      { label: 'RECEPCIÓN', id: 4 }
      // { label: 'AJUSTE', id: 5 }
    ];
    const canchas = computed(() => {
      const c = [...cleanSource(canchasState.value.map(el => ({ id: el._id, label: `${el.codigo} ${el.descripcion}` })))];
      if (!filter.tipoCancha) return [...c];
      if (filter.tipoCancha === 'PLANTA') {
        return canchasState.value.filter(el => el.tipo === 'PLANTA').map(el => ({ id: el._id, label: `${el.codigo} ${el.descripcion}` }));
      } else {
        return canchasState.value.filter(el => el.tipo !== 'PLANTA').map(el => ({ id: el._id, label: `${el.codigo} ${el.descripcion}` }));
      }
      // return [
      //   // c.pop(),
      //   // c.pop(),
      //   ...c
      // ];
    });
    const estados = [
      { id: 'VIGENTE', label: 'VIGENTE' },
      { id: 'NULA', label: 'NULA' }
    ];
    const tipos = [
      { id: 'CANCHA', label: 'CANCHA' },
      { id: 'PLANTA', label: 'PLANTA' }
    ]
    const estadoRumas = computed(() => {
      return estadoRumasState.value.map((el) => ({ id: el._id, label: el.descripcion }));
    })
    const filter = reactive<{[k: string]: any}>({
      movimiento: null,
      desde: null,
      hasta: null,
      origen: null,
      ruma: null,
      estado: null,
      tipoCancha: null,
      estadoRuma: null
    });
    const unfilter = () => {
      filter.movimiento = null;
      filter.desde = null;
      filter.hasta = null;
      filter.origen = null;
      filter.ruma = null;
      filter.estado = null; 
      filter.tipoCancha = null;
      movimientoRef.value?.clearValue();
      desdeRef.value?.clearValue();
      hastaRef.value?.clearValue();
      origenRef.value?.clearValue();
      rumaRef.value?.clearValue();  
      estadoRef.value?.clearValue();  
      tipoRef.value?.clearValue();
      estadoRumaRef.value?.clearValue();
      emit('unfilter');
    };

    const rumas = ref<{id: string, label: string}[]>([]);
    const listRumas = async() => {
      const request = await makeRequest<any>(METHODS.GET, 'rumas-simple', true, {}, { estado: '60dfe7c9ba0ccfd21a8e04f3', qty: 0 });
      if (request.ok && request.payload) {
        rumas.value = request.payload.items.map((el: any) => ({ id: el._id, label: `${el.numero} ${el.cancha.descripcion}`, numero: el.numero })).sort((a:any, b:any) => a.numero - b.numero);
      } 
    };
    onMounted(async () => {
      filter.estadoRuma = '60dfe7c9ba0ccfd21a8e04f3';
      await listRumas();
    });

    return {
      movimientos,
      canchas,
      estados,
      filter,
      unfilter,
      movimientoRef,
      desdeRef,
      hastaRef,
      origenRef,
      rumaRef,
      estadoRef,
      rumas,
      tipos,
      tipoRef,
      estadoRumaRef,
      estadoRumas,
    };
  }
});
