
import { makeRequest, METHODS } from '@/utils/apiUtils';
import moment from 'moment';
import { defineComponent, onMounted, ref } from 'vue';
import UITable from '@/components/UI/UITable.vue';
import UIModal from '@/components/UI/UIModal.vue';
import UIButton from '@/components/UI/UIButton.vue';
import { showToast } from '@/hooks/useToast';

interface IAjuste {
  _id: string;
  createdAt: number;
  updatedAt: number;
  createdBy: string;
  updatedBy: string;
  tipoAjuste: string;
  valorAjuste: number;
  valorAjusteM3: number;
  ruma: any;
}

export default defineComponent({
  name: 'Ajustes',
  components: {
    'ui-table': UITable,
    'ui-modal': UIModal,
    'ui-button': UIButton
  },
  setup() {
    const modalState = ref(false);

    const headers = [
      { label: 'Fecha', key: 'createdAt', format: '' },
      { label: 'Ruma', key: 'ruma', format: '' },
      { label: 'Cancha', key: 'cancha', format: '' },
      { label: 'Tipo', key: 'tipo', format: '' },
      { label: 'Valor', key: 'valor', format: '' },
      { label: 'ValorM3', key: 'valorM3', format: '' }
    ];
    const actions = [
      {
        label: 'ELIMINAR',
        class: 'bg-yellow-600 text-white',
        cb: (i: number) => selectRumaToDelete(i)
      }
    ];
    const items = ref<
      {
        uid: string;
        data: { createdAt: string; ruma: string; tipo: string; valor: number };
      }[]
    >([]);

    const isDeleting = ref(false);
    const ajusteToDelete = ref('');
    const selectRumaToDelete = (i: number) => {
      ajusteToDelete.value = items.value[i].uid;
      modalState.value = true;
    };
    const deleteRumaFn = async () => {
      console.log(ajusteToDelete.value);
      try {
        isDeleting.value = true;
        const request = await makeRequest(METHODS.DELETE, `/ajuste/${ajusteToDelete.value}`, true);
        if (request.ok) showToast('SUCCESS', 'Ajuste Eliminado');
        else showToast('ERROR', request.errors+'');
        await getAjustes();
      } catch (error) {
        console.log(error);
        showToast('ERROR', 'Error eliminando ruma');
      } finally {
        isDeleting.value = false;
        modalState.value = false;
      }
    };
    const closeModal = () => {
      ajusteToDelete.value = '';
      modalState.value = false;
    };


    onMounted(async () => {
      const request = await makeRequest<IAjuste[]>(
        METHODS.GET,
        '/ajustes',
        true
      );
      if (request.ok && request.payload) {
        items.value = request.payload.map((el) => ({
          uid: el._id,
          data: {
            createdAt: moment(el.createdAt).format('DD-MM-YYYY HH:mm'),
            ruma: el.ruma ? el.ruma.numero + '' : 'Ruma no existe',
            tipo: el.tipoAjuste === 'ADD' ? 'AÑADIR' : 'REDUCIR',
            valor: el.valorAjuste,
            valorM3: el.valorAjusteM3,
            cancha: el.ruma.cancha.codigo + ' ' + el.ruma.cancha.descripcion
          }
        }));
      }
    });

    const tableConfig = ref<any>({
      headers: [
        { label: 'Tipo Ajuste',  key: 'tipoAjuste', format: '', hidden: false },
        { label: 'Ruma',  key: 'ruma', format: '', hidden: false },
        { label: 'Fecha Guía',  key: 'fechaGuia', format: '', hidden: false },
        { label: 'Zona',  key: 'zona', format: '', hidden: false },
        { label: 'Origen',  key: 'origen', format: '', hidden: false },
        { label: 'Destino',  key: 'destino', format: '', hidden: false },
        { label: 'Especie',  key: 'especie', format: '', hidden: false },
        { label: 'Producto',  key: 'producto', format: '', hidden: false },
        { label: 'Intervención',  key: 'intervencion', format: '', hidden: false },
        { label: 'Largo',  key: 'largo', format: '', hidden: false },
        { label: 'Factor Corteza',  key: 'factorCorteza', format: '', hidden: false },
        { label: 'Fecha Corta',  key: 'fechaCorta', format: '', hidden: false },
        { label: 'Procedencia',  key: 'procedencia', format: '', hidden: false },
        { label: 'Estado Madera',  key: 'estadoMadera', format: '', hidden: false },
        { label: 'Valor',  key: 'valorAjuste', format: '', hidden: false },
        { label: 'Valor M3',  key: 'valorAjusteM3', format: '', hidden: false },
        { label: 'Valor MR',  key: 'valorAjusteMR', format: '', hidden: false },
        { label: 'Observaciones',  key: 'observaciones', format: '', hidden: false },
      ],
      items: [],
      clickable: true,
      actions: [
        {
          label: 'ELIMINAR',
          class: 'bg-yellow-600 text-white',
          cb: (i: number) => selectRumaToDelete(i)
        }
      ]
    });

    const getAjustes = async () => {
      const request = await makeRequest<any[]>(
          METHODS.GET,
          '/ajustes',
          true
      );
      tableConfig.value.items = request.payload?.map((el) => {
        return {
          uid: el._id,
          data: {
            tipoAjuste: el.tipoAjuste === 'ADD' ? 'Añadir' : 'Reducir',
            ruma:`${el.ruma.numero} - ${el.ruma.estadoRuma.descripcion}`,
            fechaGuia: el.fechaGuia,
            zona: `${el.zona.codigo} -  ${el.zona.descripcion}`,
            origen: el.origen && el.tipoAjuste === 'ADD' ? `${el.origen.codigo} -  ${el.origen.descripcion}`: '',
            destino: el.destino && el.tipoAjuste === 'REDUCE' ? `${el.destino.codigo} -  ${el.destino.descripcion}`: '',
            especie: `${el.especie.codigo} -  ${el.especie.descripcion}`,
            producto: el.producto.codigo,
            intervencion: `${el.intervencion.codigo} -  ${el.intervencion.descripcion}`,
            largo: el.largo,
            factorCorteza: el.factorCorteza.descripcion,
            valorAjuste: el.valorAjuste,
            valorAjusteM3: el.valorAjusteM3,
            valorAjusteMR: el.valorAjusteMR,
            procedencia: `${el.procedencia.codigo} -  ${el.procedencia.descripcion}`,
            fechaCorta: el.fechaCorta,
            observaciones: el.observaciones || '',
            estadoMadera: `${el.estadoMadera.codigo} -  ${el.estadoMadera.descripcion}`,
          }
        };
      });
    };

    onMounted(async () => {
      await getAjustes();
    });
    return {
      items,
      headers,
      actions,
      modalState,
      isDeleting,
      deleteRumaFn,
      closeModal,
      tableConfig
    };
  }
});
