<template>
  <div>
    <div class="flex">
      <div class="flex-1 px-10 py-5">
        <graph-1 />
      </div>
      <div class="flex-1 px-10 py-5">
        <graph-2 />
      </div>
    </div>
  </div>
  <div class="flex flex-col">
    <div class="flex-1 px-10 py-5" v-if="user.role === 'ADMINISTRADOR'">
      <graph-3 />
    </div>
    <div class="flex-1 px-10 py-5">
      <graph-4 />
    </div>
    <div class="flex-1 px-10 py-5">
      <graph-5 />
    </div>
  </div>
</template>

<script>
import Graph1 from "@/components/stats/Graph1";
import Graph2 from "@/components/stats/Graph2";
import Graph3 from "@/components/stats/Graph3";
import Graph4 from "@/components/stats/Graph4";
import Graph5 from "@/components/stats/Graph5";
import {computed} from "vue";
import {userState} from "@/store/user.store";

export default {
  name: "Stats",
  components: {
    Graph1,
    Graph2,
    Graph3,
    Graph4,
    Graph5
  },
  setup() {
    const user = computed(() => userState.value);

    return {
      user
    };
  }
};
</script>

<style scoped>

</style>