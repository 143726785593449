
import { formatDateDash } from '@/utils';
import { defineComponent, ref, watch } from 'vue';

export default defineComponent({
  name: 'UIFilterInput',
  props: {
    label: {
      type: String,
      default: 'label'
    },
    readonly: {
      type: Boolean,
      default: false
    },
  },
  setup(_props, { emit }) {
    
    const value = ref('');
    const selected = () => {
      emit('selected', value.value);
    };
    watch(() => value.value, (val) => {
      value.value = formatDateDash(val);
    });
    const unselect = () => {
      value.value = '';
      emit('selected', '');
    };
    const clearValue = () => {
      value.value = '';
    };
    return {
      value,
      selected,
      unselect,
      clearValue
    };
  }
});
