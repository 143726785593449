
import { ComponentPublicInstance, computed, defineComponent, onBeforeUnmount, onMounted, reactive, ref } from 'vue';
import UITable from '@/components/UI/UITable.vue';
import UITableNav from '@/components/UI/UITableNav.vue';
import UISimpleInput from '@/components/UI/UISimpleInput.vue';
import ExcelReq from '@/components/common/ExcelRequest.vue';
import TableFilter from '@/components/common/TableFilter.vue';
import { useRoute, useRouter } from 'vue-router';
import UIButton from '@/components/UI/UIButton.vue';
import UIModal from '@/components/UI/UIModal.vue';
import { makeRequest, METHODS } from '@/utils/apiUtils';
import { showToast } from '@/hooks/useToast';
import { cleanSource } from '@/utils';
import { SocketIO } from '@/utils/socketUtils';
import { userState } from '@/store/user.store';
import moment from 'moment-timezone';

export default defineComponent({
  name: 'GuidesLIst',
  components: {
    'ui-table': UITable,
    'ui-table-nav': UITableNav,
    'excel-req': ExcelReq,
    'ui-simple-input': UISimpleInput,
    'table-filter': TableFilter,
    'ui-button': UIButton,
    'ui-modal': UIModal
  },
  setup() {
    const dates = ref({ now: '', before: '' });
    const isMounted = ref(false);
    const userStoreState = computed(() => userState.value);
    const socket = SocketIO.getInstance();
    const singleGuiaSearchRef = ref<ComponentPublicInstance<typeof UISimpleInput> | null>(null);
    const route = useRoute();
    const router = useRouter();
    const routeName = computed(() => (route.meta?.routeName ? route.meta?.routeName : 'no-name'));

    const modalState = ref(false);
    const deleteGuideModalConfirmation = (i: number) => {
      guideIndexToDelete.value = i;
      modalState.value = true;
    };
    const guideIndexToDelete = ref(0);
    const deleting = ref(false);
    const isLoading = ref(false);

    const headers = ref([
      { label: 'Fecha', key: 'fechaGuia', format: '' },
      { label: 'Movimiento', key: 'movimiento', format: 'movimiento' },
      { label: 'Guía', key: 'guiaArauco', format: '' },
      { label: 'Guía Prov', key: 'guiaProveedor', format: '' },
      { label: 'Camion', key: 'patenteCamion', format: '' },
      { label: 'Carro', key: 'patenteCarro', format: '' },
      { label: 'Origen', key: 'origen', format: '' },
      { label: 'Destino', key: 'destino', format: '' },
      { label: 'Fecha Corta', key: 'fechaCorta', format: '' },
      { label: 'Intervención', key: 'intervencion', format: '' },
      { label: 'Procedencia', key: 'procedencia', format: '' },
      { label: 'Ruma', key: 'ruma', format: '' },
      { label: 'Comuna', key: 'comuna', format: '' },
      { label: 'Rut Prov', key: 'rutProveedor', format: 'dni' },
      { label: 'Nombre Prov', key: 'nombreProveedor', format: '' },
      { label: 'Peso Neto', key: 'pesoNeto', format: '' },
      { label: 'Rol Predio', key: 'rolPredio', format: '' },
      { label: 'Estado Madera', key: 'estadoMadera', format: '' },
      { label: 'Especie', key: 'especie', format: '' },
      { label: 'Largo', key: 'largo', format: '' },
      { label: 'Producto', key: 'producto', format: '' },
      { label: 'Año plantación', key: 'anioPlantacion', format: '' },
      { label: 'VolMR Original', key: 'originalMR', format: '', hidden: false },
      { label: 'Volumen MR', key: 'volumenMR', format: '' },
      { label: 'Volumen MSSC', key: 'volumenMSSC', format: '' },
      { label: 'Observación', key: 'observaciones', format: '' },
      { label: 'Estado Guía', key: 'estado' }
    ]);
    const actions = ref([
      {
        label: 'EDITAR',
        class: 'bg-green-400 text-white',
        cb: (i: number) => goToEditGuia(i)
      }
    ]);

    if (userStoreState.value?.role !== 'CONSULTOR' && userStoreState.value?.role !== 'SUPERVISOR') {
      actions.value.push(
        ...[
          {
            label: 'ESTADO',
            class: 'bg-yellow-600 text-white',
            cb: (i: number) => guiaStatusHandler(i)
          },
          {
            label: 'ELIMINAR',
            class: 'bg-red-600 text-white',
            cb: (i: number) => deleteGuideModalConfirmation(i)
          }
        ]
      );
    } else {
      actions.value[0].label = 'VER';
    }

    const items = ref<{ uid: string; data: any }[]>([]);
    const itemsCount = reactive<{
      total: number;
      actual: number;
      initial: number;
    }>({ total: 0, actual: 0, initial: 0 });

    const guiaStatusHandler = async (index: number) => {
      const guia = items.value[index];
      const STATUSES = {
        VIGENTE: 'VIGENTE',
        NULA: 'NULA'
      };
      const url = `/guia-status?id=${guia.uid}&status=${guia.data.estado === STATUSES.NULA ? STATUSES.VIGENTE : STATUSES.NULA}`;
      const request = await makeRequest(METHODS.PATCH, url, true);
      if (request.ok) {
        showToast('SUCCESS', 'Estado actualizado exitosamente');
      } else {
        showToast('ERROR', request.errors ? request.errors : 'Error actualiando guía');
      }
    };
    const deleteGuia = async () => {
      if (!modalState.value) return;
      deleting.value = true;
      try {
        const guia = items.value[guideIndexToDelete.value];
        const url = `/guia/${guia.uid}`;
        const request = await makeRequest(METHODS.DELETE, url, true);
        if (request.ok) {
          showToast('SUCCESS', 'Guia eliminada exitosamente');
        } else {
          showToast('ERROR', request.errors ? request.errors : 'Error eliminando guía');
        }
      } catch (error: any) {
        console.log(error.message);
        showToast('ERROR', error.message);
      } finally {
        modalState.value = false;
        deleting.value = false;
      }
    };
    const goToEditGuia = (i: number) => {
      const guia = items.value[i];
      router.push({ name: 'guides.create', query: { id: guia.uid } });
    };

    const filter = reactive<{ [k: string]: any }>({
      movimiento: null,
      desde: null,
      hasta: null,
      origen: null,
      destino: null,
      estado: null,
      tipoCancha: null,
      estadoRuma: null,
      qty: 10,
      page: 1,
      guia: ''
    });
    const mapGuide = (el: any) => {
      return {
        uid: el._id,
        data: {
          _id: el._id,
          movimiento: el.movimiento,
          guiaArauco: el.guiaArauco,
          guiaProveedor: el.guiaProveedor,
          fechaGuia: el.fechaGuia,
          especie: el.especie,
          producto: el.producto,
          ruma: el.ruma ? el.ruma?.numero + '' : '',
          estado: el.estado,
          patenteCamion: el.patenteCamion,
          patenteCarro: el.patenteCarro,
          origen: el.origen,
          destino: el.destino,
          fechaCorta: el.fechaCorta,
          intervencion: el.intervencion,
          procedencia: el.procedencia,
          comuna: el.comuna,
          rutProveedor: el.rutProveedor,
          nombreProveedor: el.nombreProveedor,
          pesoNeto: el.pesoNeto,
          rolPredio: el.rolPredio,
          largo: el.largo,
          anioPlantacion: el.anioPlantacion,
          volumenMR: el.volumenMR,
          volumenMSSC: el.volumenMSSC,
          originalMR: el.originalMR,
          observaciones: el.observaciones,
          estadoMadera: el.estadoMadera,
          guideDateOnMs: el.guideDateOnMs,
          volumenRecepcion: el.volumenRecepcion,
          volumenDespacho: el.volumenDespacho
        }
      };
    };
    const reMapGuide = (el: any) => {
      el.data.origen = el.data.origen.codigo + ' ' + el.data.origen.descripcion;
      el.data.destino = el.data.destino.codigo + ' ' + el.data.destino.descripcion;
      el.data.intervencion = el.data.intervencion.descripcion;
      el.data.procedencia = el.data.procedencia.descripcion;
      el.data.especie = el.data.especie.descripcion;
      el.data.producto = el.data.producto.codigo;
      el.data.comuna.descripcion ? (el.data.comuna = el.data.comuna.codigo) : el.data.comuna;
      el.data.originalMR = el.data.movimiento === 1 || el.data.movimiento === 4 ? el.data.volumenRecepcion : el.data.volumenDespacho;
      return el;
    };
    const getGuides = async () => {
      try {
        isLoading.value = true;
        const params = cleanSource(filter);
        // params.per_page = filter.qty;
        // delete params.qty;
        const url = '/guias';
        const request = await makeRequest<{
          total: number;
          items: any[];
        }>(METHODS.GET, url, true, {}, params);
        if (request.payload?.items) {
          itemsCount.actual = request.payload.items.length;
          itemsCount.total = request.payload.total;
          itemsCount.initial = 1;
          items.value = request.payload?.items.map((el) => mapGuide(el));
        }
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
    const onFilterRequest = async (f: { [k: string]: any }) => {
      if (isLoading.value) return;
      filter.movimiento = f.movimiento;
      filter.desde = f.desde;
      filter.hasta = f.hasta;
      filter.origen = f.origen;
      filter.ruma = f.ruma;
      filter.estado = f.estado;
      filter.tipoCancha = f.tipoCancha;
      filter.estadoRuma = f.estadoRuma;
      filter.guia = '';
      singleGuiaSearchRef.value?.clearValue();
      await getGuides();
      showToast('SUCCESS', 'Filtro Aplicado');
    };
    const unfilter = async () => {
      console.log('unfilter');
      filter.movimiento = null;
      filter.desde = null;
      filter.hasta = null;
      filter.origen = null;
      filter.ruma = null;
      filter.estado = null;
      filter.tipoCancha = null;
      filter.estadoRuma = null;
      filter.page = 1;
      filter.guia = '';
      singleGuiaSearchRef.value?.clearValue();
      await getGuides();
    };
    const onPagedRequest = async (q: number) => {
      if (isLoading.value) return;
      filter.qty = q;
      filter.page = 1;
      filter.guia = '';
      singleGuiaSearchRef.value?.clearValue();
      await getGuides();
    };
    const onPageRequest = async (page: number) => {
      if (isLoading.value) return;
      filter.page = page;
      filter.guia = '';
      // itemsCount.initial = 0;
      // itemsCount.total = 0;
      // itemsCount.actual = 0;
      singleGuiaSearchRef.value?.clearValue();
      await getGuides();
    };
    const searchSingleGuide = async () => {
      filter.movimiento = null;
      filter.desde = null;
      filter.hasta = null;
      filter.origen = null;
      filter.destino = null;
      filter.estado = null;
      filter.estadoRuma = null;
      filter.page = 1;
      await getGuides();
    };

    const requestReport = async () => {
      const params = cleanSource(filter);
      params.qty = 0;
      params.page = 1;
      console.log(params)
      const url = '/report/guide';
      return makeRequest<{
        total: number;
        items: any[];
      }>(METHODS.POST, url, true, { descripcion: 'Guias' }, params);
    };

    const addGuia = (guia: any) => {
      if (filter.page > 1) return;
      const reMapped = [mapGuide(guia)].map((el) => reMapGuide(el))[0];
      items.value.unshift(reMapped);
      items.value.sort((a, b) => (a.data.guideDateOnMs || 0) - (b.data.guideDateOnMs || 0));
      if (items.value.length > filter.qty) items.value.pop();
    };
    const updateGuideStatus = (payload: { _id: string; status: string }) => {
      console.log('!!!');
      const guia = items.value.find((el) => el.uid === payload._id);
      if (guia) {
        guia.data.estado = payload.status;
      }
    };
    const deleteGuide = (id: string) => {
      items.value = items.value.filter((el) => el.uid !== id);
    };
    const updateSingleGuide = (payload: { _id: string; populated: any; filled: any }) => {
      const guia = items.value.find((g) => g.uid === payload._id);
      if (!guia) return;
      const { filled, populated } = payload;
      guia.data.movimiento = filled.movimiento;
      guia.data.guiaArauco = filled.guiaArauco;
      guia.data.guiaProveedor = filled.guiaProveedor;
      guia.data.fechaGuia = filled.fechaGuia;
      guia.data.especie = populated.especie.descripcion;
      guia.data.producto = populated.producto.codigo;
      guia.data.ruma = populated.ruma?.numero;
      guia.data.estado = filled.estado;
      guia.data.patenteCamion = filled.patenteCamion;
      guia.data.patenteCarro = filled.patenteCarro;
      guia.data.origen = filled.origen.includes('undefined') ? populated.origen.codigo + ' ' + populated.origen.descripcion : filled.origen;
      guia.data.destino = filled.destino.includes('undefined') ? populated.destino.codigo + ' ' + populated.destino.descripcion : filled.destino;
      guia.data.fechaCorta = filled.fechaCorta;
      guia.data.intervencion = populated.intervencion.descripcion;
      guia.data.procedencia = populated.procedencia.descripcion;
      guia.data.comuna = filled.comuna;
      guia.data.rutProveedor = filled.rutProveedor;
      guia.data.nombreProveedor = filled.nombreProveedor;
      guia.data.pesoNeto = filled.pesoNeto || 0;
      guia.data.rolPredio = populated.rolPredio || 0;
      guia.data.largo = filled.largo;
      guia.data.anioPlantacion = filled.anioPlantacion;
      guia.data.volumenMR = filled.volumenMR;
      guia.data.volumenMSSC = filled.volumenMSSC;
      guia.data.originalMR = filled.originalMR;
      guia.data.observaciones = filled.observaciones;
      guia.data.estadoMadera = populated.estadoMadera;
      guia.data.guideDateOnMs = filled.guideDateOnMs;
      guia.data.volumenRecepcion = filled.volumenRecepcion || 0;
      guia.data.volumenDespacho = filled.volumenDespacho || 0;
    };
    
    onMounted(async () => {
      filter.estadoRuma = '60dfe7c9ba0ccfd21a8e04f3';
      await getGuides();
      isMounted.value = true;
      const roomEvents = [
        { evt: 'new-guide', cb: addGuia },
        { evt: 'guide-update-status', cb: updateGuideStatus },
        { evt: 'guide-delete', cb: deleteGuide },
        { evt: 'guide-update-single', cb: updateSingleGuide }
      ];
      socket.joinRoom(`guias-${userStoreState.value?.cancha}`, roomEvents);
      // if (userStoreState.value?.role === 'ADMINISTRADOR') {
      //   const i = headers.value.findIndex((el) => el.key === 'originalMR');
      //   headers.value[i].hidden = false;
      // }

      const now = moment().format('YYYY-MM-DD');
      const before = moment().subtract(3, 'months').format('YYYY-MM-DD');
      dates.value.now = now;
      dates.value.before = before;
    });
    onBeforeUnmount(async () => {
      await socket.leaveRoom(`guias-${userStoreState.value?.cancha}`);
    });

    return {
      routeName,
      modalState,
      headers,
      actions,
      items,
      itemsCount,
      guiaStatusHandler,
      deleteGuia,
      deleting,
      onFilterRequest,
      onPagedRequest,
      onPageRequest,
      filter,
      unfilter,
      singleGuiaSearchRef,
      searchSingleGuide,
      requestReport,
      userStoreState,
      isLoading,
      isMounted,
      dates
    };
  }
});
